ul.slimmenu li.active a,
.collapse-button ul.slimmenu li.active a {
    color: #daa214;
}
.collapse-button ul.slimmenu {
    position: fixed;
    width: 100%;
    margin: 0px 0 0;
    left: 0;
    background: #fff;
    top: 45px;
    list-style-type: none;
    margin: 0;
    padding:15px 15px 0px 15px;
}

.collapse-button ul.slimmenu li {
    width:100%;
    position: relative;
    display: inline-block;
    margin: 0 1px;
    background-color: transparent;
    border-bottom: solid 1px #dde6ef;
    text-align: left;
}

.collapse-button ul.slimmenu>li {
    margin-right: 0;
    border-left: 0 solid #999;
    padding: 0 0px
}

.collapse-button ul.slimmenu li a {
    color: #677782; font-size: 15px; text-transform: capitalize; font-weight: 500; font-family: 'Nunito', sans-serif; padding: 0 11px !important; line-height: 60px !important;
}

.collapse-button ul.slimmenu li a.active,
.collapse-button ul.slimmenu li a:hover { color:#f8b91f;}
