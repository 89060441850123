.lightbox-title{
    display: none;
}
.lightbox-description{
    display: none;
}
.gallery_list .big-image{
    height: 150px;
}

ul.gallery_list li{
    cursor: pointer;
}
ul.gallery_list.long li{
    width:15%;
}


@media only screen and (max-width:767px){
    ul.gallery_list.long li{
        width:29%;
    }
}